import { Link } from "@StarberryUtils"
import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import YoutubeScubscribe from "./youtube-subscribe.png";
import './SubscribeBlock.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../Play/PlayVideo";
import NewsletterForm from "@Components/forms/newsletter-form";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown"
import LoadExternalScript from "@Components/utils/load-external-script";
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
const SubscribeBlock = (props) => {
  const [isPlay, setPlay] = useState(false);
  const locRatingScriptsJs = 'https://apis.google.com/js/platform.js';
  const [showVideo, setShowVideo] = useState(false);
  const playVideo = (video_id) => {  
    // setVideoindex(video_index)   
    setShowVideo(true)
}
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }
  const data = useStaticQuery(graphql`
  query {

  glstrapi {
    youtubeSubscribe {
      imagetransforms
      id
      Embed_Video_URL
      Content
      Title
      Image {
        alternativeText
        url
      }
    }
  }

}
`);
if (data.glstrapi?.youtubeSubscribe?.Embed_Video_URL) {
  var videoid = getVideoId(data.glstrapi?.youtubeSubscribe?.Embed_Video_URL);
 }

 let processedImages = JSON.stringify({});
 if (data.glstrapi?.youtubeSubscribe?.imagetransforms?.Image_Transforms) {
     processedImages = data.glstrapi?.youtubeSubscribe?.imagetransforms.Image_Transforms;
 }

return (
    <div className="subscribe-block">
       <LoadExternalScript 
              src={locRatingScriptsJs}
              // async="true"
              defer="true"
              // appendScriptTo="head"
            />

      <Container>

        <Row>
          <Col lg="4" className="order-lg-2">
            <ScrollAnimation className="relative" animateIn='fadeIn' animateOnce={true} delay={300}>
            {showVideo && <div onClick={(e) => {setShowVideo(false)}} className="close-iframe-btn"></div> }
              <div className="video-block img-zoom">
              {showVideo &&
                        <YouTube
                        video={videoid.id}
                        autoplay
                        onEnd={e => {setShowVideo(false)}}
                        modestBranding={1}
                        onPlaying={trackerVideo('Subscribe Video')}
                        showRelatedVideos={false}
                        showInfo={false}
                        annotations={false}
                        
                        />
                        }
                <picture>
                  <ImageTransform imagesources={data.glstrapi?.youtubeSubscribe?.Image?.url} renderer="srcSet" 
          imagename='youtube-subscribe.Image.tail'
                                     attr={{ alt: data.glstrapi?.youtubeSubscribe?.Image?.alternativeText, className: 'propery-img'}}
                                     imagetransformresult={processedImages} id={data.glstrapi?.youtubeSubscribe?.id} />
                </picture>
                {showVideo ? null :
                data.glstrapi.youtubeSubscribe.Embed_Video_URL ? 
                <strong className="play-btn" onClick = { (e) => {playVideo(videoid.id)}}></strong> : null
                }
              </div>
            </ScrollAnimation>
          </Col>
          <Col lg="8" className="order-lg-1">
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <div className="subscribe-content">
                <h2>{data.glstrapi.youtubeSubscribe.Title}</h2>
                <div className="rich-text-editer-block">
                <ReactMarkdown source={data.glstrapi.youtubeSubscribe.Content} allowDangerousHtml />
                </div>
                <div className="subscribe-form">
                 
                  <NewsletterForm />
                  <div className="form-text">
                      <span>By subscribing, you agree to our <Link to="/terms-and-conditions">Terms & Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.</span>
                    </div>

                    <div className="subscribe-text">
                      <a href="https://www.youtube.com/channel/UCbok1WpAXT-I1586sYJRXbw?sub_confirmation=1" target="_blank"><img src={YoutubeScubscribe} className="youtube-subscribe" /></a>
                      <p><strong> Subscribe to our YouTube channel </strong> to get notified of all our new video content.</p>
                    </div>

                </div>
              </div>
            </ScrollAnimation>
          </Col>
        </Row>
        <PlayVideo
          isOpen={isPlay}
          isCloseFunction={setPlay}
          videoId={data.glstrapi.youtubeSubscribe.Embed_Video_URL}
          isAutoPlay={1}
        />
      </Container>
    </div>
  )
}

export default SubscribeBlock;

