import React, {useState, useEffect} from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"
import './ContentPageBlock.scss';
import ReactMarkdown from "react-markdown"
import GetURL from '@Components/common/site/get-url';
import { useLocation } from "@reach/router";
import { uknumberformatFront } from "@Components/common/common-functions"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import { Helmet } from "react-helmet";
import {
  isRentLetPage, isRentPages, isLetPages
} from "@Components/common/site/get-pages";
import {
  TORENT_Manchester_PAGE_URL
} from "@Components/common/site/constants";
const ContentPageBlock = (props) => {
  let processedImages = JSON.stringify({});
  if (props?.Speak_With?.imagetransforms?.Image_Transforms) {
      processedImages = props?.Speak_With?.imagetransforms.Image_Transforms;
  }

  const [isClient, setClient] = useState(false);
  useEffect(() => {

    setClient(true);
  }, [])
  const location = useLocation();
  const thePath  = location.pathname

  return (
    <div className="property-content static-page-content">
    <Helmet>
  <script type="application/ld+json">{`{
 "@context": "http://schema.org/",
  "@type": "Service",
  "serviceType": "Property Management",
  "brand": "https://www.bentleyhurst.co.uk/",
  "serviceOutput": "${props.Title}",
  "description": "${props.Meta_Description}",
  "image": {
        "@type": "ImageObject",
        "caption": "${props.Banner_Title}",
        "contentUrl": "${props.Content[0].Image?.url}"
        },
  "provider": {
    "@type": "Organization",
    "name": "Bentley Hurst",
    "@id": "https://www.bentleyhurst.co.uk/images/mail-logo.png"
  },
  "areaServed": {
    "@type": "State",
    "name": "Manchester"
  }

   }`}</script>
 </Helmet>
 {thePath =='/property-services/buy/guide-to-buying/' &&
 <Helmet>
  <script type="application/ld+json">{`{
      "@context": "https://schema.org/", 
      "@type": "HowTo", 
      "name": "Buying a home? Start with the best advice",
     "description": "Whether you’re buying a house for the first time or just need a quick reminder about the buying process, find out the answers to your questions here",
      "image": "https://www.bentleyhurst.co.uk/images/mail-logo.png",
      "step": [{
        "@type": "HowToStep",
        "text": "Our friendly and experienced team at Bentley Hurst are ready to guide you along your home buying journey with honest, expert advice and locally tailored information.",
        "name": "Guide to Buying Property in Manchester"
      },{
        "@type": "HowToStep",
        "text": "Register today with Bentley Hurst to get information on the latest properties coming onto the market, complete with a 3D Virtual tour, professional photographs and extensive local knowledge about things like local schools, transport links, restaurants and bars etc. When you have found the places that you really love, book your viewings to see the properties in person.",
        "name": "Start your search"
      },{
        "@type": "HowToStep",
        "text": "It's important at this stage to consider other costs when buying a property, including surveyor and solicitor fees. Bentley Hurst works with Embrace financial services, who can provide help and guidance throughout the process. Embrace gives you access to thousands of mortgage deals from high-street lenders, sourced and reviewed by experts. When you have the mortgage agreed in principle, it’s time to make the offer. Make sure you can provide the following information: your offer price, your buying position, proof of funds, mortgage/ agreement in principle, proof of ID and proof of address, as well as your solicitor’s details.",
        "name": "Source a Mortgage Agreement in Principle and make an offer"
      },{
        "@type": "HowToStep",
        "text": "Great news, your offer has been accepted! The next thing you need to do is choose a conveyancing specialist to act for you in buying the property. Bentley Hurst works with a panel of carefully selected property lawyers, solicitors and conveyancing firms and can even advise you as you book in a survey for the property, whether that’s a mortgage valuation survey, a homebuyer survey, or a full building survey.",
        "name": "Acceptance, legal and survey"
      },{
        "@type": "HowToStep",
        "text": "Once you're ready to go ahead and any required finances are in place, your conveyancer will proceed with the legal process. We will ensure the purchase of your new home is going through as quickly and smoothly as possible throughout the process, with frequent communication and reliable advice. And when your conveyancer has confirmed your purchase has exchanged and completed, you can collect the keys to your new home!",
        "name": "Closing the sale"
      }]
    
  }`}</script>
</Helmet>}
{thePath =='/property-services/sell/guide-to-selling/' &&
 <Helmet>
  <script type="application/ld+json">{`{
      "@context": "https://schema.org/", 
      "@type": "HowTo", 
      "name": "Guide to Selling your House in Manchester",
     "description": "Looking for a few top tips for selling your home? Check out our simple guide to help you",
      "image": "https://www.bentleyhurst.co.uk/images/mail-logo.png",
      "step": [{
        "@type": "HowToStep",
        "text": "We all need a little refresher sometimes. So our simple guide to selling your property gives you the essential information in a quick and easy format. Find out what order you need to complete your tasks and what to expect from your buyer, agent and solicitors as you sell.",
        "name": "An easy guide to help you through the process."
      },{
        "@type": "HowToStep",
        "text": "First up, you need to instruct the estate agent, giving them permission to proceed and value your property. They’ll help you market your home using a combination of photography, videos, floorplans and written material which they can share with potential buyers. At Bentley Hurst, we create 3D professional photography, virtual walkthroughs and social media boosting, too. Your property will be in the shop window, the website, as well as on popular listings sites like Zoopla and Rightmove.",
        "name": "Instructing and Marketing"
      },{
        "@type": "HowToStep",
        "text": "Buyers will enjoy seeing your property looking at its best, and we can help you as you prepare it for viewings and photos. Once you’re ready, we can start to show interested buyers around in person. Our comprehensive marketing with video and 3D means that buyers tend to be more committed when they arrive in person. When they make an offer, we’ll make sure it’s a sensible one and can be backed up financially before presenting it to you with our informed advice.",
        "name": "Viewings and Offers"
      },{
        "@type": "HowToStep",
        "text": "Once you accept an offer, we will send the paperwork to your conveyancer to begin the sale process. We stay in touch with you and your buyer, making sure your sale maintains a healthy momentum. We can also recommend working with one of our network of property solicitors and conveyancing firms to guarantee seamless service.",
        "name": "Conveyancing"
      },{
        "@type": "HowToStep",
        "text": "If the solicitors are satisfied, then you can move ahead with the exchange of contracts, releasing your deposit and activating the buyer’s mortgage offer. From there, things can move quickly: you can set a preferred date with the seller, and on moving day, you can complete the purchase when the buyer’s solicitors release the remainder of the sales price to your solicitor’s account. Congratulations! ",
        "name": "Exchange and Completion"
      }]   
    
  }`}</script>
</Helmet>}
{thePath =='/property-services/rent/guide-to-renting/' &&
 <Helmet>
  <script type="application/ld+json">{`{
     "@context": "https://schema.org/", 
     "@type": "HowTo", 
     "name": "Guide to Renting Property in Manchester",
    "description": "Looking for rental advice as you find a property? Check out our handy guide to renting a home and find out what you need to know.",
     "image": "https://www.bentleyhurst.co.uk/images/mail-logo.png",
     "step": [{
       "@type": "HowToStep",
       "text": "Are you about to rent a property? If you’re looking for a quick guide to the rental process, then we can help. Here’s the Bentley Hurst approach to renting.",
       "name": "Make renting run smoothly for you"
     },{
       "@type": "HowToStep",
       "text": "Take some time to think about your income and your outgoings so you can calculate your budget.",
       "name": "Work out finances"
     },{
       "@type": "HowToStep",
       "text": "Register today with Bentley Hurst to get information on the latest properties coming onto the market, complete with a 3D Virtual tour, professional photographs and extensive local information about things like local schools, transport links, restaurants and bars etc",
       "name": "Start your search"
     },{
       "@type": "HowToStep",
       "text": "Once you have found the properties you’re looking for, it is now the time to start having a look at them in person!",
       "name": "View properties"
     },{
       "@type": "HowToStep",
       "text": "As soon as you find the right property, it's time to put in an offer. Make sure you outline the following details to us: the price you want to pay for the monthly rent, length of tenancy you require, any requests you may have and the date you would like to move in. ",
       "name": "Making an offer"
     },{
       "@type": "HowToStep",
       "text": "Great news, your offer has been accepted! ",
       "name": "Offer accepted"
     },{
       "@type": "HowToStep",
       "text": "Holding deposits are now taken. This is equivalent to one weeks rent and the agent will take the property off the market. It is important that you disclose at this stage anything that may have an adverse affect on your credit rating for referencing purposes.",
       "name": "Pay holding deposit"
     },{
       "@type": "HowToStep",
       "text": "The tenancy will be subject to successful referencing which include credits checks, employment and landlord references. ",
       "name": "References commence"
     },{
       "@type": "HowToStep",
       "text": "This is the legal contract between you, the tenant and the landlord. ",
       "name": "Signing the tenancy agreement"
     },{
       "@type": "HowToStep",
       "text": "On the day of move in, you will pay your deposit, one months rent, collect your keys and move into your new home!",
       "name": "Move in"
     }]  
   
  }`}</script>
</Helmet>}
{thePath =='/property-services/landlord/guide-to-letting/' &&
 <Helmet>
  <script type="application/ld+json">{`{
     "@context": "https://schema.org/", 
     "@type": "HowTo", 
     "name": "Guide to Letting Property in Manchester",
    "description": "If you’re renting out a property for the first time or just want a bit of guidance as you let a property, consult our simple guide first.",
     "image": "https://www.bentleyhurst.co.uk/images/mail-logo.png",
     "step": [{
       "@type": "HowToStep",
       "text": "Choosing the right agent is crucial. Bentley Hurst’s friendly and experienced agents will appraise your property and advise you on the best price in the current local market before you let, and advise you on any changes that could attract the right kind of tenant for your needs in the right timeframe.",
       "name": "Get the basics done before you let your property"
     },{
       "@type": "HowToStep",
       "text": "You can choose several levels of management, depending on how involved you want to be in the letting process. Our most popular service is Fully Managed, where a team of experienced professionals look after your property over the whole tenancy. This includes 3D professionally enhanced property marketing in-branch and online. We carry out comprehensive referencing, including credit checks, previous landlord and employment references, and we prepare the tenancy agreement and a thorough inventory report before your tenants move in.",
       "name": "How do you want your property managed?"
     },{
       "@type": "HowToStep",
       "text": "Our marketing department will get your property in front of the right tenants for your needs, and our extensive contacts book will help locate renters who may not turn up from a standard search. Marketing is crucial: you want to attract just the right tenant who matches your property and has the background that aligns with your expectations.",
       "name": "Market your property"
     },{
       "@type": "HowToStep",
       "text": "Showcasing your property at its best helps you find the right tenant at the right price. Take a moment to look round your home through the eyes of a prospective tenant first: emphasise features that would attract you, and try to resolve any issues that could cause doubt or raise questions. We’re available to accompany tenants on viewings if required and advise you on their feedback to help you improve your property in the eyes of the next tenants.",
       "name": "Accept viewings from prospective tenants"
     },{
       "@type": "HowToStep",
       "text": "All offers are pre-qualified by us before being put forward to you as the landlord. We will advise and help you make an informed decision on which offer you would like to go ahead with, based on the facts that we present.",
       "name": "Receiving and Accepting an offer:"
     },{
       "@type": "HowToStep",
       "text": "The Tenant will pay a holding deposit fee of one week’s rent to secure the property while carrying out comprehensive referencing via Experian. This includes credit checks, previous landlord and employment references, so you know that the tenant is 100% suitable before they move in. 
   Ensuring your tenant is suitable before moving in. On the move-in date, the tenant will sign their contracts, pay their security deposit and first month’s rent. You are now a landlord — congratulations! ",
       "name": "Holding Deposit Fee and Moving In"
     }] 
   
  }`}</script>
</Helmet>}


      <Container>
        {props.layout === 'Static_Page_Basic' ?
          <Row>
            <Col md="8">
              <h1 className="page-title">{props.Title}</h1>
            </Col>
          </Row>
          : null}
        <Row>
          <Col md="8">
            {props.Content && props.Content.length > 0 ?
              props.Content.map(item => {
                return (
                  <div className="static-page-content-block">
                    <div className="rich-text-editer-block">
                    <ReactMarkdown source={item?.Content_Block} allowDangerousHtml />
                    </div>
                    <div className="image-blk">
                      <img src={item.Image?.url} alt={item.Image?.alternativeText} />
                    </div>
                  </div>
                )
              }) : ''}
          </Col>
          <Col md="4">
            {props.Speak_With !== null ?
              <div className="property-quote">
                {props.Right_Bar !== null ?
                  <div className="quote-info d-none d-xl-block">
                    <h4 className="quote-title">{props.Right_Bar?.Right_Title}</h4>
                    <div className="rich-text-editer-block">
                    <ReactMarkdown source={props.Right_Bar?.Content} allowDangerousHtml />
                    </div>
                    {props.Right_Bar?.Cta_Btn_Block_Right?.length > 0 && isClient ?
                      <div className="quote-btns">
                        {props.Right_Bar?.Cta_Btn_Block_Right?.map((item, i) => {
                          return (i===0 ?
                            <GetURL label={item.Label} alias={item.all_menus?.Alias} class="btn btn-primary" />
                            : 
                            i === 1 && isRentLetPage() ? 
                            <Link to={ TORENT_Manchester_PAGE_URL.alias} class="btn btn-outline">{item.Label}</Link>
                            :
                            <GetURL label={item.Label} alias={item.all_menus?.Alias} class="btn btn-outline" />
                          )

                        })}
                      </div>
                      : null}
                  </div> : null}
                <div className="negotiator d-flex align-items-center">
                  <figure>
                  <ImageTransform imagesources={props.Speak_With?.Image?.url} renderer="srcSet" 
          imagename='team.Image.speakwith'
                                     attr={{ alt: props.Speak_With?.Image?.alternativeText, className: ''}}
                                     imagetransformresult={processedImages} id={props?.Speak_With?.id} />
                  </figure>
                  <div className="info">
                    <strong className="name">{props.Speak_With?.Name}</strong>
                    <p className="post">{props.Speak_With?.Designation}</p>
                    <a className="contact-no" href={"tel:" + uknumberformatFront(props.Speak_With?.Phone)}>{uknumberformatFront(props.Speak_With?.Phone)}</a>
                  </div>
                </div>

              </div>
              : null}
          </Col>
        </Row>

      </Container>
    </div>
  )
}



export default ContentPageBlock